import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { getUser } from "../../actions/user/user";
import APIUrl from "../../APIUrl";
import { logout } from "../../actions/authentication/authentication";
import Util from "../../util/Util";

import { Link } from "react-router-dom";

class Eforbizz extends React.Component {
  constructor(props) {
    super(props);

    this.props.onGetUser();
  }

  // Logout from app
  logout(e, url) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;

    function onLogoutSuccess() {
      // Redirect to another e4BIZZ app after logout
      if (url && url.indexOf("http") !== -1) {
        window.location.replace(url);
      }
      // No redirection: pure logout
      else {
        that.props.history.push("/");
      }
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  render() {
    let appCount = [];
    if (this.props.user.access_e4coll)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4COLL_URL));

    if (this.props.user.access_e4mad)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4MAD_URL));

    if (this.props.user.access_e4vhp)
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4VHP_URL));
    if (
      this.props.user.access_e4pharmamad ||
      this.props.user.access_e4pharmahome ||
      this.props.user.access_e4pharmastore ||
      this.props.user.access_e4pharmadesk
    )
      appCount.push(Util.checkHttps(process.env.REACT_APP_E4PHARMAMAD_URL));

    // User have access to a single app: we automatically redirect to this app
    if (appCount.length === 1) {
      if (
        appCount[0] === Util.checkHttps(process.env.REACT_APP_E4PHARMAMAD_URL)
      ) {
        this.props.history.push("/eforbizz/pharma-mad");
        return null;
      }

      window.location.replace(appCount + APIUrl.jwtToken);
      return false;
    }

    let e4collUrl = "/eforbizz";
    let e4vhpUrl = "/eforbizz";
    let e4madUrl = "/eforbizz";
    let e4pharmaUrl = "/eforbizz";

    // Check that user has the access rights
    if (this.props.user.access_e4coll)
      e4collUrl = Util.checkHttps(process.env.REACT_APP_E4COLL_URL);
    if (this.props.user.access_e4vhp)
      e4vhpUrl = Util.checkHttps(process.env.REACT_APP_E4VHP_URL);

    if (this.props.user.access_e4mad)
      e4madUrl = Util.checkHttps(process.env.REACT_APP_E4MAD_URL);
    if (this.props.user.access_e4pharmamad)
      e4pharmaUrl = Util.checkHttps(process.env.REACT_APP_E4PHARMAMAD_URL);
    else if (this.props.user.access_e4pharmahome)
      e4pharmaUrl = Util.checkHttps(process.env.REACT_APP_E4PHARMAHOME_URL);
    else if (this.props.user.access_e4pharmastore)
      e4pharmaUrl = Util.checkHttps(process.env.REACT_APP_E4PHARMASTORE_URL);
    else if (this.props.user.access_e4pharmadesk)
      e4pharmaUrl = Util.checkHttps(process.env.REACT_APP_E4PHARMADESK_URL);

    if (e4collUrl.indexOf("http") !== -1) e4collUrl += APIUrl.jwtToken;
    if (e4vhpUrl.indexOf("http") !== -1) e4vhpUrl += APIUrl.jwtToken;
    if (e4madUrl.indexOf("http") !== -1) e4madUrl += APIUrl.jwtToken;
    if (e4pharmaUrl.indexOf("http") !== -1) e4pharmaUrl += APIUrl.jwtToken;

    // Apps logos
    let e4collLogo = "./images/svg/Logos-EFORCOLL.png";
    let e4vhpLogo = "./images/svg/Logos-EFORVHP.png";
    let e4madLogo = "./images/svg/Logos-EFORMAD.png";
    let e4pharmaLogo = "./images/svg/logo_E4PHARMA.png";

    return (
      <React.Fragment>
        <div className="divEforbizz">
          <div className="text-center">
            <img
              alt=""
              className="w-25 img-fluid"
              src="./images/svg/logo_e4BIZZ.svg"
            />
          </div>

          <div className="m-5 text-center">
            <div className="row">
              {this.props.user.access_e4coll && (
                <div className="card">
                  <h5 className="card-header">
                    <img alt="appLogo" src={e4collLogo} />
                  </h5>
                  <div className="card-body">
                    {/* <p className="card-text">Pour les EHPAD et établissements de santé (Sanitaires et médico-sociaux)</p> */}
                    {/* <Link to={e4collUrl} className="btn btn-info"><FormattedMessage id="Open.App" /></Link> */}
                    <button
                      onClick={(e) => this.logout(e, e4collUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}

              {this.props.user.access_e4mad && (
                <div className="card">
                  <h5 className="card-header">
                    <img alt="appLogo" src={e4madLogo} />
                  </h5>
                  <div className="card-body">
                    {/* <p className="card-text">Pour les prescripteurs, aidants et familles de patients en perte d’autonomie.</p> */}
                    <button
                      onClick={(e) => this.logout(e, e4madUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}

              {this.props.user.access_e4vhp && (
                <div className="card">
                  <h5 className="card-header">
                    <img alt="appLogo" src={e4vhpLogo} />
                  </h5>
                  <div className="card-body">
                    {/* <p className="card-text">Pour les prescripteurs, aidants et familles de patients en perte d’autonomie.</p> */}
                    <button
                      onClick={(e) => this.logout(e, e4vhpUrl)}
                      className="btn btn-info"
                    >
                      <FormattedMessage id="Open.App" />
                    </button>
                  </div>
                </div>
              )}

              {(this.props.user.access_e4pharmamad ||
                this.props.user.access_e4pharmahome ||
                this.props.user.access_e4pharmastore ||
                this.props.user.access_e4pharmadesk) && (
                <div className="card">
                  <h5 className="card-header">
                    <img alt="appLogo" src={e4pharmaLogo} />
                  </h5>
                  <div className="card-body">
                    {/* <p className="card-text">Pour les centres de rééducation fonctionnelle et ergothérapeutes afin d’équiper les personnes handicapées physiques.</p> */}
                    <Link to={"/eforbizz/pharma-mad"} className="btn btn-info">
                      <FormattedMessage id="Open.App" />
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={(e) => this.logout(e)}
              className="btn btn-secondary mt-5"
            >
              <FormattedMessage id="Disconnect" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Eforbizz));

/*
class Link extends React.Component {

    render() {
        return /^https?:\/\//.test(this.props.to)
            // eslint-disable-next-line
            ? <a href={this.props.to} {...this.props} />
            : <RouterLink {...this.props} />
    }

}
*/
