import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);

var APIUrl = {
  // Authentication
  login: rootUrl + "/login", // POST {username, password}
  logout: rootUrl + "/logout", // GET

  // User
  getUser: rootUrl + "/user", // GET
  updateLang: rootUrl + "/user/lang/", // GET {lang = ["en", "fr"]}
  checkIfUsernameExists: rootUrl + "/user/check/username/", // GET {username}
  checkIfEmailExists: rootUrl + "/user/check/email/", // GET {email}
  updateUser: rootUrl + "/user/update", // POST {updatedField, updatedValue}
  getAdmin: rootUrl + "/user/getadmin", // GET {}

  // Password forgotten
  addToken: rootUrl + "/passwordrecoverytokens/add", // POST {email}
  resetPassword: rootUrl + "/passwordrecoverytokens/resetpassword/", // POST {password}

  // Companies
  checkIfCompanyUrlExists: rootUrl + "/company/check/url/", // GET {url}
  checkIfUpdatedEmailExists: rootUrl + "/user/check/updatedemail/", // GET {updatedemail}
  getCompany: rootUrl + "/company", // GET
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
