import APIUrl from "../../APIUrl";
import axios from "axios";

export const addToken = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addToken, data)
      .then(function (response) {
        if (successCallback) successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const resetPassword = function (token, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.resetPassword + token, data)
      .then(function (response) {
        return successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
