import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const PharmaRedirect = () => {
  const handleRedirect = () => {
    window.location.href = "https://login.pharma-mad.com/";
  };

  return (
    <div style={styles.container}>
      <img height={"250em"} alt="" src="./images/svg/pharmamad_logo.svg" />
      <div className="text-left mt-5">
        <h3>
          <FormattedMessage id="Pharma.New.Title" />
        </h3>
        <p>
          <FormattedMessage id="Pharma.New.Url" />{" "}
          <a href="https://login.pharma-mad.com">
            https://login.pharma-mad.com
          </a>
          <br />
          <FormattedMessage id="Pharma.New.Fav" />
        </p>
      </div>
      <Button style={styles.button} onClick={handleRedirect}>
        <FormattedMessage id="Pharma.New.Connection" />
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    padding: "20px",
  },
  button: {
    backgroundColor: "#BAD702",
    borderColor: "#BAD702",
  },
};

export default PharmaRedirect;
