import { combineReducers } from "redux";
import user from "./user/userReducer";
import i18n from "./i18n/i18nReducer";
import url from "./authentication/authentication";

import { LOGOUT } from "../actions/authentication/authentication";

const appReducer = combineReducers({
  user: user,
  i18n: i18n,
  url: url,
});

const urlReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Wipe all redux state
    state = undefined;
  }

  return appReducer(state, action);
};

export default urlReducer;
