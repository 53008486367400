import React from "react";
import {
  Popover,
  OverlayTrigger,
  PopoverTitle,
  PopoverContent,
} from "react-bootstrap";

class PopoverHelper extends React.Component {
  render() {
    const title = this.props.title || false;
    const content = this.props.content || false;
    const placement = this.props.placement || "right";
    const trigger = this.props.trigger || "click";

    let myPopover = (
      <Popover>
        {title && <PopoverTitle>{title}</PopoverTitle>}
        <PopoverContent className="text-justify">{content}</PopoverContent>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        overlay={myPopover}
      >
        <i className="fa fa-question-circle-o ml-1"></i>
      </OverlayTrigger>
    );
  }
}

export default PopoverHelper;
