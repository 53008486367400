import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

class ErrorModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <div className="modal-bg show" onClick={() => this.close()}>
        <div className="modal fade show">
          <div
            className="modal-dialog"
            role="document"
            onClick={(e) => this.stopEvent(e)}
          >
            <div className="modal-content border border-danger">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <i className="fa fa-warning fa-big mr-3"></i>
                  {this.props.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.close()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{this.props.content}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => this.close()}
                >
                  <FormattedMessage id="Ok" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
