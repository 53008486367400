import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { Offline } from "react-detect-offline";
class NetworkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      check_connectivity: localStorage.getItem("check_connectivity") || false,
      mode: this.props.mode
        ? this.props.mode !== "banner"
          ? "modal"
          : "banner"
        : "banner",
    };
  }

  close() {
    this.props.close();
  }

  render() {
    const style = {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 1062,
      borderRadius: 0,
      borderColor: "#fff",
      backgroundColor: "#ffd757",
      fontWeight: "bold",
    };

    return this.state.check_connectivity === "true" ? (
      <Offline>
        {this.state.mode === "modal" ? (
          <div
            className="modal-bg"
            style={{
              backgroundColor: "rgba(255, 243, 205, 0.8)",
              zIndex: "1061",
            }}
          >
            <Modal
              show={true}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "1062" }}
              onHide={() => this.close()}
              centered
            >
              <Modal.Header>
                <Modal.Title className="text-danger">
                  <i className="fa fa-wifi mr-1"></i>
                  <FormattedMessage id="Network.Connectivity.Title" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <FormattedMessage id="Network.Connectivity.Error.1" />
                </p>
                <p>
                  <FormattedMessage id="Network.Connectivity.Error.2" />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="btn btn-danger" onClick={() => this.close()}>
                  <FormattedMessage id="Close" />
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div
            style={style}
            className="alert alert-warning text-center mb-0"
            role="alert"
          >
            <FormattedMessage id="Network.Connectivity.Error.1" />{" "}
            <FormattedMessage id="Network.Connectivity.Error.2" />
          </div>
        )}
      </Offline>
    ) : (
      ""
    );
  }
}

export default NetworkModal;
