import APIUrl from "../../APIUrl";
import axios from "axios";
import { getUserAction } from "../user/user";

export const LOGOUT = "LOGOUT";
export const GET_URL = "GET_URL";

function getLogoutAction() {
  return { type: LOGOUT };
}

export const login = function (
  username,
  password,
  successCallback,
  failureCallback,
) {
  return function (dispatch) {
    // Avoid useless HTTP request if username and password are not provided
    if (!username || !password) {
      return failureCallback("EmptyCredentials");
    }

    return axios
      .post(APIUrl.login, { username: username, password: password })
      .then(function (response) {
        // A 203 status from the backend means that too much unsuccessful attempts have been registered (avoid brute force)
        // The login for this account is then suspended for a certain period
        if (response.status === 203) {
          return failureCallback(response.data.message);
        }

        // Add jwt token to headers
        axios.defaults.headers.common["jwtToken"] = response.headers.jwttoken;
        APIUrl.jwtToken = response.headers.jwttoken;

        // Add JWT token to storage
        sessionStorage.setItem("jwt", response.headers.jwttoken);

        dispatch(getUserAction(response.data.user));

        return successCallback(response.data.user);
      })
      .catch(function (err) {
        return failureCallback();
      });
  };
};

export const logout = function (successCallback) {
  return function (dispatch) {
    // Delete tokens
    axios.defaults.headers.common["jwtToken"] = null;

    delete axios.defaults.headers.jwtToken;
    delete axios.defaults.headers.common["jwtToken"];
    delete APIUrl.jwtToken;

    // Clear session storage
    sessionStorage.clear();

    dispatch(getLogoutAction());
    successCallback();
  };
};
